.animate-spin {
    animation: spin 1s linear infinite;
}

#dropzone {
    color: red;
    background: rgb(129 3 3 / 3%);
    border: 1px dashed #da0d0d;
}

.dz-image {
    width: 150px !important;
}

.dz-details .dz-size {
    display: none !important;
}

.dz-preview {
    background: transparent !important;
}

.dz-upload {
    background: #6CDA91 !important;
}

.dz-progress {
    background: transparent !important;
}

.dz-image img {
    height: 100% !important;
    width: 100% !important;
    cursor: pointer !important;
}

/* HELP CLASS */
.my-mb-1 {
    margin-bottom: -1px;
}

.w-fit {
    width: fit-content;
}

/* FINE HELP CLASS */

/* MESSAGGI ERRORE */
.fira {
    font-family: 'Fira Code', monospace;
}

.dark-text-color {
    color: #405200;
}

.dark-bg-color {
    background: #405200;
}

.error-title {
    @apply fira dark-text-color text-3xl mb-2
}

.error-code {
    @apply fira dark-text-color text-6xl mb-2
}

.error-message {
    @apply dark-text-color fira text-xl mb-8
}

.error-link {
    @apply fira rounded-md text-white dark-bg-color py-1 px-2 uppercase hover:bg-green-600
}

/* FINEMESSAGGI ERRORE */


.btn-submit {
    @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
}

.btn-submit-negative {
    @apply inline-flex justify-center py-2 px-4 border border-green-500 shadow-sm text-sm font-medium rounded-md text-green-500 bg-white hover:bg-green-700 hover:border-transparent hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
}

.tab-active {
    @apply border-green-500 text-green-600 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300
}

.btn-header {
    fill: #fff
}

.btn-header:hover {
    fill: #789d00
}

.btn-document-pre {
    @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-900 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300
}

/*Expire session timer*/
.timer {
    display: flex;
}

.timer span + span:before {
    content: ":"
}

/*
*
*
DA CANCELLARE FINITA OTTIMIZZAZIONE TABELLE
*
*
*/

/* TABELLE */
.link-tab {
    @apply text-green-400 font-bold hover:no-underline underline
}

.text-tab {
    @apply px-6 py-4 whitespace-nowrap text-sm text-gray-900
}

.th-tab {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider
}

/*
*
*
CANCELLARE FINO A QUI
*
*
*/

/* MIEI PULSANTI DA USARE */
.myBtn-default {
    @apply py-2 px-2 text-sm text-white bg-green-500 hover:bg-green-700 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 cursor-pointer uppercase
}

.myBtn-alert {
    @apply py-2 px-2 text-sm text-white bg-red-600 hover:bg-red-700 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 cursor-pointer uppercase
}

.myBtn-blank {
    @apply py-2 px-2 text-sm text-gray-900 bg-white hover:bg-gray-200 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 cursor-pointer uppercase
}

.myBtn-bin {
    @apply p-2 text-sm font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
}

/* MIEI LINK */
.myLink-esc {
    @apply text-gray-600 text-sm underline hover:no-underline uppercase
}

/* TITLE */
.my-title {
    @apply text-lg sm:text-xl mb-2 text-gray-700 font-bold
}

/* TABELLE */
.myTable {
    @apply min-w-full divide-y divide-gray-200
}

.myTable thead tr {
    @apply bg-gray-50
}

.myTable thead tr th {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider
}

.myTable tbody tr {
    @apply bg-white hover:bg-gray-100 border-t border-gray-200
}

.myTable tbody tr td {
    @apply px-6 py-4 whitespace-nowrap text-sm text-gray-900
}

.tableStats tbody tr td, .tableStats thead tr th {
    @apply px-2 py-4 text-center
}

.tableStats tr td:first-child {
    @apply text-left
}

.myTable .link-tab {
    @apply text-green-400 font-bold hover:no-underline underline
}


/* Numerazione tabella */
.tab_num-ecc {
    @apply cursor-pointer border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium
}

.tab_num-select {
    @apply cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium
}

.tab_num {
    @apply cursor-pointer border-green-400 text-green-400 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium
}

.prev-next-page {
    @apply text-gray-600 cursor-pointer
}

.listselect {
    @apply absolute bg-white border border-green-600 overflow-hidden rounded-md w-full z-10
}

.listselect_item {
    @apply p-1 hover:bg-gray-200 text-sm cursor-pointer
}


/* Checkbox */
.my-checkbox {
    @apply text-sm text-green-600 border-gray-300 rounded focus:outline-none focus:ring-green-600
}


/* Input */
.myInput-label {
    @apply block text-sm text-gray-700
}

.myInput-text {
    @apply text-sm text-gray-900 p-2 rounded-md w-full max-w-full border border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500
}

.myInput-text-disable {
    @apply text-sm text-gray-900 p-2 rounded-md w-full max-w-full border border-gray-300
}

.myInput-text-error {
    @apply text-sm text-red-500 p-2 rounded-md w-full max-w-full border border-red-500 focus:outline-none focus:ring-red-500 focus:border-red-500
}

.myInput-textarea {
    @apply text-sm text-gray-900 p-2 rounded-md w-full max-w-full border border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500
}

.myInput-textarea-disable {
    @apply text-sm text-gray-900 p-2 rounded-md w-full max-w-full border border-gray-300 h-36 overflow-y-auto
}

.myInput-select {
    @apply text-sm text-gray-900 rounded-md block w-full pl-3 pr-10 border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500
}

.myInput-search {
    @apply text-sm text-gray-900 rounded-md block w-full max-w-full border border-gray-300 pl-10 pt-2 pb-2 focus:outline-none focus:ring-green-500 focus:border-green-500
}

/* disabilitato */
text:disabled, textarea:disabled, select:disabled {
    background-color: transparent;
}

input[type="checkbox" i]:disabled {
    color: #bbb;
    border-color: #bbb;
}

input[type="text" i]:disabled {
    background-color: transparent;
}

/* Sottopagine Area titolo pagina */
.submenu {
    @apply flex items-center p-2 mx-1 text-xs sm:text-sm text-white uppercase bg-green-400 rounded-md hover:bg-white hover:text-green-400 focus:outline-none
}

.submenu-selected {
    @apply flex items-center p-2 mx-1 text-xs sm:text-sm text-green-400 uppercase bg-white rounded-md focus:outline-none
}

/** Modale Mappa */
#map, #map2 {
}

.modal {
    transition: opacity 0.25s ease;
}
.modal-active {
    overflow-x: hidden;
    overflow-y: visible !important;
}

.scroll{
    height: 40vh;
    overflow-y: visible;
    overflow-x: hidden;
    direction: ltr;
    /*position of scroll bar can use rtl if wanted, but use div * {direction: ltr;} if you do.} */
    scrollbar-width: thin;/*fancy width*/
    scrollbar-color: #7cb342 #f3f0dd;/*fancy colors for Firefox*/
}

.scroll::-webkit-scrollbar {
    width: 11px;
}

.scroll::-webkit-scrollbar-track {
    background: #f3f0dd;
}

.scroll::-webkit-scrollbar-thumb {
    background-color: #7cb342;
    border-radius: 6px;
    border: 3px solid #7cb342;
}

#map {
    height: 80vh;
    width: 100%;
}

/* CASASERVICE */

.cs-btn {
    @apply justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-900 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-900
}

.cs-btn:disabled {
    @apply bg-gray-300
}

/* TAB CASASERVICE*/
.cs-tab-active {
    background-color: #f5f5f5 !important;
    color: #881337 !important;
}

/* Input CASASERVICE */
.cs-input-label {
    @apply block text-sm text-gray-700
}

.cs-input-text {
    @apply text-sm text-gray-900 p-2 rounded-md w-full max-w-full border border-gray-300 focus:outline-none focus:ring-red-900 focus:border-red-900
}

.cs-input-search {
    @apply text-sm block leading-8 w-full pl-10 bg-red-100 rounded-md focus:bg-white focus:outline-none focus:ring-red-900 focus:border-red-900
}

/* Checkbox */
.cs-my-checkbox {
    @apply text-sm text-red-900 border-gray-900 rounded focus:outline-none focus:ring-red-900
}

/* List select */
.cs-my-listselect {
    @apply absolute bg-white border border-red-900 overflow-hidden rounded-md w-full z-10
}

.cs-my-listselect_item {
    @apply p-1 hover:bg-gray-200 text-sm cursor-pointer
}

/* FINE CASASERVICE */


