@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-SemiBold.woff2') format('woff2'),
    url('/fonts/Nunito-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-Bold.woff2') format('woff2'),
    url('/fonts/Nunito-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-Regular.woff2') format('woff2'),
    url('/fonts/Nunito-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}